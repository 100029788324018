import * as React from "react";
import "../styles/global.css";
import Layout from "../layout/layout";
import Card from "../components/card";
import {useStaticQuery, graphql} from "gatsby";

const IndexPage = () => {

  const cardData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, filter: {frontmatter: {type: {eq: "post"}}}) {
        edges {
          node {
            fields {
              slug
            }
            excerpt
            frontmatter {
              author
              date(formatString: "MMMM DD, YYYY")
              title
              type
              thumbnail {
                childImageSharp {
                  fixed(width: 250, height: 250) {
                    ...GatsbyImageSharpFixed
                  }
                }
          }
            }
            timeToRead
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <div className="animate-fadeIn">
        {cardData.allMarkdownRemark.edges.map( edge => {
          return(
            <Card title={edge.node.frontmatter.title}
                  author={edge.node.frontmatter.author}
                  date={edge.node.frontmatter.date}
                  readTime={edge.node.timeToRead}
                  excerpt={edge.node.excerpt}
                  imageFixed={edge.node.frontmatter.thumbnail.childImageSharp.fixed}
                  slug={`blog${edge.node.fields.slug}`}>
            </Card>
          )
        })}
      </div>
    </Layout>
  )
}

export default IndexPage;
